<template>
  <div class="slide-wrap">
    <div class="head">
      <img src="./imgs/slideBack.jpg" alt="" class="head-back">
      <div class="level">
        <img src="./imgs/A1.png" alt="" class="level-img">
      </div>
      <div class="descs">
        <div class="desc">我的积分:</div>
        <div class="num">1500</div>
      </div>
    </div>
    <div class="menus">
      <div class="menu" v-for="(item, idx) in menus" :key="idx" :class="{ active: active == item.text }"
        @click="handleMenu(item)">
        <div class="icon">
          <img v-if="active != item.text" :src="item.iconA" alt="" class="icon-img">
          <img v-if="active == item.text" :src="item.iconB" alt="" class="icon-img">
        </div>
        <div class="text">
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import A0 from './imgs/A0.png';
import A1 from './imgs/A1.png';
import B0 from './imgs/B0.png';
import B1 from './imgs/B1.png';
import C0 from './imgs/C0.png';
import C1 from './imgs/C1.png';
import D0 from './imgs/D0.png';
import D1 from './imgs/D1.png';
import E0 from './imgs/E0.png';
import E1 from './imgs/E1.png';
export default {
  data() {
    return {
      active: '工作台',
      menus: [{
        text: '工作台',
        iconA: A0,
        iconB: A1,
        path: '/work/home',
      }, {
        text: '企业信息',
        iconA: B0,
        iconB: B1,
        path: '/work/home/company',
      }, {
        text: '政策匹配',
        iconA: C0,
        iconB: C1,
        path: '/work/home/company-match',
      },{
        text: '政策推送',
        iconA: C0,
        iconB: C1,
        path: '/work/home/company-push',
      },{
        text: '会员管理',
        iconA: D0,
        iconB: D1,
        path: '/work/home/member',
      },{
        text: '购买套餐',
        iconA: E0,
        iconB: E1,
        path: '/work/home/buy-vip',
      }]
    }
  },
  mounted() {
    // console.log('mounted', this.$route.fullPath);
    let defaultCurrent = this.menus.find(f => f.path == this.$route.fullPath);
    this.active = defaultCurrent?.text || '工作台';
  },
  methods: {
    handleMenu(item) {
      this.active = item.text;
      this.$router.push(item.path);
    },
  }
}
</script>
<style lang="less" scoped>
.slide-wrap {
  width: 230px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 16px rgba(51, 135, 255, .12);

  .head {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 132px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .head-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .level {
      position: relative;
      width: 32px;
      height: 32px;

      .level-img {
        width: 100%;

      }
    }

    .descs {
      position: relative;
      margin-top: 24px;
      font-size: 18px;
      color: #fff;
      display: flex;

      .num {
        color: #fffd00;
        margin-left: 12px;
      }
    }
  }

  .menus {
    .menu {
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      transition: all ease .25s;
      cursor: pointer;

      &.active {
        background: #e3f3ff;

        .text {
          color: #20a5ff;
        }
      }

      .icon {
        margin: 0 30px;

        .icon-img {
          width: 24px;
          height: 24px;
        }
      }

      .text {
        flex: 1;
        text-align: left;
        font-size: 20px;
        transition: all ease .25s;
      }
    }
  }
}
</style>