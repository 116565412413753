<template>
  <div class="page">
    <div class="header">
      <div class="wrap">
        <div class="inner">
          <div class="logo">
            <img src="@/assets/logo2.png" alt="" class="logo-img">
          </div>
          <div class="nav">
            <a-menu @click="handleItem" :selectable="false" theme="dark" class="nav-menu" @select="handleChangeMenu"
              v-model:selectedKeys="activeKey" mode="horizontal" size="large">
              <template v-for="item in menus" :key="item.id">
                <a-menu-item :key="item.url" v-if="!item.sub_menu.length">{{ item.mc }}</a-menu-item>
              </template>
            </a-menu>
          </div>
          <div class="btns">
            <!-- <div class="label-vip">
              <img src="@/assets/vip-list.png" alt="" class="vip-button" @click="buyVip">              
            </div> -->
            <div class="label">
              <img src="@/assets/prevBtn.png" alt="" class="user">
              <div class="username">工作台|</div>
              <div class="username" v-if="userinfo?.phone">{{ userinfo?.phone.substring(0, 3) + '****' +
                userinfo?.phone.substring(7) }}</div>
            </div>
            <div class="btn" @click="exit">
              <img src="@/assets/icons/out.png" alt="退出登录" class="icon">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subheader">

    </div>
    <div class="main">
      <div class="layout">
        <div class="slide">
          <slide v-if="isHome" />
        </div>
        <div class="content">
          <router-view />
        </div>
      </div>
      <div class="footer">
        <Footer />
      </div>
    </div>
  </div>
</template>
<script>
import service from '@/service/service';
import { mapMutations, mapState } from 'vuex';
import Slide from './components/slide.vue';
import Footer from './components/footer.vue';

export default {
  data() {
    return {
      activeKey: [],
      menus: [],
    };
  },
  mounted() {
    if (!this.logoUrl) {
      this.getLogo();
    }
    this.getMenus();
  },
  computed: {
    ...mapState(["userinfo", "logoUrl"]),
    isHome() {
      return this.$route.fullPath.includes('/home');
    }
  },
  methods: {
    ...mapMutations(["setLogoUrl", 'setUserinfo']),
    handleChangeMenu(e) {
      // console.log(e);
      if (e.key)
        this.$router.push(e.key);
    },
    async getMenus() {
      // const { data: menus } = await service.get_my_menu({ system_id: 20 });
      const menus = [
        {
          mc: "首页",
          url: "/prev/preview",
          sub_menu: []
        },
        {
          mc: "政策信息",
          url: "/prev/policy",
          sub_menu: [],
        },
        {
          mc: "知识产权",
          url: "/work/zxcq",
          sub_menu: [],
        },
        {
          mc: "产业技术",
          url: "/work/zyjs",
          sub_menu: [],
        },
        {
          mc: "高端人才",
          url: "/work/gdrc",
          sub_menu: [],
        },
        {
          mc: "政务数据",
          url: "/work/zwsj",
          sub_menu: [],
        },
      ];
      this.menus = menus;
      // this.activeKey = [menus?.[0]?.url];
    },
    handleItem({ key }) {
      this.$router.push(key);
      // console.log(item, key);
    },
    async getLogo() {
      const { data } = await service.get_setting_info({ key: "system_logo" });
      // console.log(data);
      let files = data.icon ? JSON.parse(decodeURI(data.icon)) : [];
      this.logoUrl = files[0] || null;
      if (this.logoUrl) {
        this.setLogoUrl(this.logoUrl.url);
      }
    },
    buyVip(){
      console.log("bbbb")
      this.$router.push("/work/home/buy-vip");
    },
    exit() {
      window.sessionStorage.removeItem("token");
      this.setUserinfo(['userinfo', null]);
      this.$router.replace("/prev/preview");
    }
  },
  components: { Slide, Footer }
}
</script>
<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    flex: 0 0 auto;

    .wrap {
      height: 68px;
      // background: #fff;
      background: #003466;
      box-shadow: 0 0 16px rgba(51, 135, 255, .12);

      .inner {
        width: 1400px;
        margin: auto;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          height: 100%;

          .logo-img {
            height: 100%;
            width: auto;
            vertical-align: top;
          }


        }

        .nav {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: center;

        }

        .btns {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .btn {
            width: 32px;
            height: 32px;
            margin-left: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .icon {
              width: 100%;
              // height:100%;
              height: auto;
              vertical-align: top;
            }
          }
          .label-vip{
            border-radius: 10px;
            color: #fff;
            display: flex;
            align-items: center;
            padding: 0 6px;
            height: 32px;
            margin-right: 6px;
            cursor: pointer;

            .vip-button{
              height: 35px;
              
            }
          }
          .label {
            background: @primary-color;
            // border-radius: @border-radius;
            border-radius: 10px;
            color: #fff;
            display: flex;
            align-items: center;
            padding: 0 6px;
            height: 32px;
            margin-right: 6px;
            cursor: pointer;

            .user {
              width: 26px;
              height: 26px;
              
            }

            

            .username {
              font-size: 20px;
              letter-spacing: 1px;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .main {
    flex: 1;
    overflow: auto;

    .layout {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .slide {
      width: 278px;
      padding: 24px;
    }

    .content {
      width: 100%;
      min-height: calc(100vh - 68px - 168px);
    }
  }
}
</style>