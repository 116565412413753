<template>
  <div class="footerWrap">
    <div class="cont">
      <div class="desc">
        <div class="name">浙江企政牛网络科技有限公司</div>
        <div class="label">©2023 By QZNIU Co.Ltd. All Rights Reserved.</div>
      </div>
      <div class="info">
        <div class="phone">
          <img src="@/assets/icons/phone.png" alt="" class="phone-img icon">
          <span class="text">400</span>
          <span class="text">610</span>
          <span class="text">6699</span>
        </div>
        <div class="phone">
          <img src="@/assets/icons/user2.png" alt="" class="phone-img icon">
          <span class="text">浙江省杭州市未来科技城数字产业园</span>
        </div>
      </div>
      <div class="code">
        <div class="code-wrap">
          <img src="@/assets/icons/code.jpg" alt="" class="code-img">
          <div class="tip">企业客服微信</div>
        </div>
      </div>
    </div>
    <div class="bah">
      <span>浙ICP备 20232236551号</span>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .footerWrap {
    height:168px;
    padding-top:34px;
    background:#003366;
    .cont {
      width:1400px;
      margin:auto;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      .desc {
        .name {
          font-size:23px;
          color:#fff;
        }
        .label {
          margin-top:6px;
          padding:0 16px;
          height:24px;
          line-height:24px;
          color:#fff;
          font-size:13px;
          border-radius:12px;
          background:#2d4589;
        }
      }
      .info {
        .phone {
          display: flex;
          align-items: center;
          margin-bottom:12px;
          .icon {
            width:22px;
            margin-right:12px;
          }
          .text {
            font-size:23px;
            color:#fff;
            margin-right: 3px;;
            &:last-child {
              margin:0;
            }
          }
        }
      }
      .code {
        .code-wrap {
          width:88px;
          box-sizing: border-box;
          padding:6px;
          .code-img {
            width:100%;
          }
          .tip {
            margin-top:6px;
            color:#fff;
            font-size:12px;
            text-align: center;
          }
        }
      }
    }
    .bah {
      text-align:center;
      margin-top:-15px;
      font-size:15px;
      color:#fff;
    }
  }
</style>